@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
}

.hero,
.why-us {
  background-image: url("./images/assets/home/desktop/bg-pattern-hero-home.svg");
  background-size: cover;
}

.about-page {
  clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
  background-image: url("./images/assets/home/desktop/bg-pattern-hero-home.svg");
  background-size: cover;
}

.about-home {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.contact-image {
  width: 100%;
  height: 50vh;
  border-radius: 20px;
}

.image-gallery-slide .image-gallery-description {
  background-color: #fff !important;
  color: black !important;
}

@media (max-width: 767px) {
  .contact-image {
    height: 30vh;
  }
}
